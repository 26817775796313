import { HTMLAttributes, useState, ReactNode } from "react";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { GroupItem, IconContainer, THead } from "./styles";
import { FormCheck } from "react-bootstrap";

export interface CustomTheadProps extends HTMLAttributes<HTMLHeadElement> {
  data: {
    column: string;
    name: ReactNode | ReactNode[] | string | number | boolean;
    menu?: boolean;
  }[];
  checkbox?: boolean;
  selectedAll?: boolean;
  onChangeSelectAll?: React.ChangeEventHandler<HTMLInputElement>;
  onChangeOrder?(event: { [column: string]: string }): void;
}

export interface CustomTheadData<T> {
  column: keyof T;
  name: ReactNode | ReactNode[] | string | number | boolean;
  menu?: boolean;
}

export default function CustomThead({
  data,
  onChangeOrder,
  checkbox,
  selectedAll,
  onChangeSelectAll,
  ...props
}: CustomTheadProps) {
  const [order, setOrder] = useState<number>(3);
  const [selected, setSelected] = useState<number>();

  const handleOrder = (column: string, index: number) => {
    let _order: number = 3;

    if (!selected && order >= 3) _order = 1;
    else if (selected === index && order === 1) _order = 2;
    else if (selected === index && order === 2) return resetOrder();
    else _order = 1;

    setOrder(_order);
    setSelected(index);
    onChangeOrder?.({ [column]: formatOrderAscOrDesc(_order) });
  };

  const resetOrder = (): void => {
    setOrder(3);
    setSelected(undefined);
    onChangeOrder?.({ default: "desc" });
  };

  const formatOrderAscOrDesc = (orderIndex: number): string => {
    switch (orderIndex) {
      case 1:
        return "asc";
      case 2:
        return "desc";
      default:
        return "desc";
    }
  };

  const getSelected = (index: number, orderBy: number): boolean => {
    return selected === index && order === orderBy;
  };

  return (
    <THead {...props}>
      <tr>
        {data?.map((item, i) => (
          <th key={`table_${item.column}_${i}`}>
            <GroupItem>
              {checkbox && i === 0 && (
                <FormCheck
                  id={`checkbox-${i}`}
                  checked={selectedAll}
                  onChange={onChangeSelectAll}
                />
              )}
              {typeof item.name !== "string" && item.name}

              <GroupItem
                style={{ cursor: !!item?.menu ? "auto" : "pointer" }}
                onClick={() => handleOrder(item.column, i)}
              >
                {typeof item.name === "string" && (
                  <div className="text-uppercase">{item.name}</div>
                )}

                {!item?.menu && (
                  <IconContainer>
                    <BsFillCaretUpFill
                      className={getSelected(i, 2) ? "opacity-25" : ""}
                      size={10}
                    />
                    <BsFillCaretDownFill
                      className={getSelected(i, 1) ? "opacity-25" : ""}
                      size={10}
                    />
                  </IconContainer>
                )}
              </GroupItem>
            </GroupItem>
          </th>
        ))}
      </tr>
    </THead>
  );
}
