import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as IcLogoDark } from "../../assets/img/logo_dark.svg";
import { ReactComponent as CarrierWebLogo } from "../../assets/img/logo_carrierweb_login.svg";
import LoginCarousel from "../../components/LoginCarousel";
import { Login } from "../../core/interfaces/Login";
import { useAppDispatch } from "../../core/redux/hooks";
import { updateToken, updateUser } from "../../core/redux/reducer/auth";
import { LoginResolver } from "../../core/schemaValidations/user";
import { CredentialsService } from "../../core/services/CredentialsService";
import { isErrorResult } from "../../core/utils/api";
import PublicMasterPage from "../../layout/PublicMasterPage";
import { Container } from "./styles";
import img_not_video from "../../assets/img/video_error.png";
import { useTranslation } from "react-i18next";
import { Logo } from "../../components/Logo";

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const { t, i18n } = useTranslation();
  const local = useLocation();
  const carrierWeb = ["localhost:3000", "carrierweb.fleetdesk.io"];
  const currentHost = window.location.host;
  const isCarrierWeb = carrierWeb.includes(currentHost);
  // console.log("local -->", local);
  // console.log("local -->", window.location);
  // console.log("local 3 -->", carrierWeb?.includes(window.location.host));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Login>({
    resolver: LoginResolver,
  });

  useEffect(() => {
    if (localStorage.getItem("@FleetDesk:remember")) {
      reset(JSON.parse(atob(localStorage.getItem("@FleetDesk:remember")!)));
    }
  }, [reset]);

  useEffect(() => {
    //@ts-ignore
    var userLang = navigator.language || navigator.userLanguage;

    const localStorageLanguage = localStorage.getItem("@FLEET_DESK_LANGUAGE");
    if (!localStorageLanguage) {
      localStorage.setItem("@FLEET_DESK_LANGUAGE", userLang);
      i18n.changeLanguage(userLang);
    } else {
      i18n.changeLanguage(localStorage.getItem("@FLEET_DESK_LANGUAGE") ?? "");
    }
  }, []);

  const onSubmit = async (data: Login) => {
    setErrorMessage("");
    const _result = await CredentialsService.login(data);
    const isError = isErrorResult(_result);
    console.log("onSubmit", { isError, _result });

    if (data.remember_password) {
      localStorage.setItem("@FleetDesk:remember", btoa(JSON.stringify(data)));
    } else if (localStorage.getItem("@FleetDesk:remember")) {
      localStorage.removeItem("@FleetDesk:remember");
    }

    if (!isError) {
      dispatch(updateUser(_result.user));
      localStorage.setItem("LANGUAGE_ID", _result.user.languages_id.toString());

      dispatch(updateToken(_result.token));
      // dispatch(updateLocales(_result.locales?.[0]));
    } else if (_result.message) {
      setErrorMessage(_result.message);
    }
  };
  const onError = (error: any) => console.log("onSubmitError", error);

  return (
    <PublicMasterPage>
      <img style={{ display: "none" }} src={img_not_video} />
      <Container fluid id="login">
        <Row>
          <Col className="p-0" xs={6}>
            <LoginCarousel />
          </Col>
          <Col xs={6} className="p-0 right">
            <div className="right-container">
              <Logo />
              <div id="form-container">
                <div className="wellcome-message">
                  <div className="title">{t("login.welcome_message")}</div>
                  <div className="sub-title">{t("login.subtitle")}</div>
                </div>
                <Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Login</Form.Label>
                    <Form.Control
                      {...register("login")}
                      type="email"
                      placeholder={t("login.email_placeholder")}
                      autoCapitalize="off"
                      // autoComplete="off"
                      autoCorrect="off"
                      isInvalid={!!errors?.login?.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.login?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>{t("login.password_label")}</Form.Label>
                    <Form.Control
                      {...register("password")}
                      type="password"
                      placeholder={t("login.password_placeholder")}
                      autoCapitalize="off"
                      autoComplete="off"
                      autoCorrect="off"
                      isInvalid={!!errors?.password?.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.password?.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row>
                    <Col xs="7">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          {...register("remember_password")}
                          type="checkbox"
                          label={t("login.remember_password")}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Link
                        className="forgot-password text-right"
                        to="forgot-password"
                      >
                        {t("login.recover_password")}
                      </Link>
                    </Col>
                  </Row>

                  {errorMessage && (
                    <div className="error-message">
                      {"Usuário ou senha inválido"}
                    </div>
                  )}

                  <Button variant="primary" type="submit">
                    Login
                  </Button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PublicMasterPage>
  );
};

export default LoginPage;
