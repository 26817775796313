// Teste build 
import React from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./App.css";
import i18n from "./config/i18n";
import RoutesRoot from "./routes";
import { store } from "./core/redux/store";
function App() {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <I18nextProvider key="default" i18n={i18n}>
          <RoutesRoot />
        </I18nextProvider>
      </DndProvider>
    </Provider>
  );
}

export default App;
