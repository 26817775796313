import React, { HTMLAttributes } from "react";

import { Button, Spinner } from "react-bootstrap";
import Pagination, { IPaginationProps } from "../Pagination";
import { ButtonsContainer, Container, PaginateContainer } from "./styles";

interface TableFooterActionProps {
  onSubmit?(): void;
  onCancel?(): void;
  isSubmitting?: boolean;
  titleSubmit?: string;
  titleCancel?: string;
  hidden?: boolean;
}

interface TableFooterProps extends HTMLAttributes<HTMLDivElement> {
  paginate: IPaginationProps;
  actions: TableFooterActionProps;
  hiddenAction?: boolean;
}
const TableFooter: React.FC<TableFooterProps> = ({
  paginate,
  actions,
  hiddenAction,
  ...props
}) => {
  return (
    <Container {...props}>
      {!hiddenAction && (
        <ButtonsContainer hidden={actions?.hidden}>
          <Button onClick={actions?.onSubmit}>
            {actions?.titleSubmit || "Submit"}
            {actions?.isSubmitting && <Spinner animation="border" size="sm" />}
          </Button>

          <Button variant="secondary" onClick={actions?.onCancel}>
            {actions?.titleCancel || "Cancel"}
          </Button>
        </ButtonsContainer>
      )}

      <PaginateContainer>
        <Pagination {...paginate} />
      </PaginateContainer>
    </Container>
  );
};

export default TableFooter;
