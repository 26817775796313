export function convertToYMDHMS(date: Date) {
  if (isNaN(date.getTime())) {
    return "";
  } else {
    console.log(date, "aa");
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:00`;
  }
}

export function limitText(text: string, count: number) {
  return text.slice(0, count) + (text.length > count ? "..." : "");
}
