import React, { useEffect, useState } from "react";
import { Stack, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AsyncPaginate } from "react-select-async-paginate";
import { Controller, useForm } from "react-hook-form";

import { ReactComponent as IcPajamas } from "../../assets/img/sidebar/ic_pajamas.svg";
import { ReactComponent as IcLogo } from "../../assets/img/sidebar/ic_logo.svg";
import { ReactComponent as IcDashboard } from "../../assets/img/sidebar/ic_dashboard.svg";
import { ReactComponent as IcTrips } from "../../assets/img/sidebar/ic_routing.svg";
import { ReactComponent as IcDrivers } from "../../assets/icons/ic-driver.svg";
import { ReactComponent as IcRouting } from "../../assets/icons/ic-routing.svg";
import { ReactComponent as IcSettings } from "../../assets/icons/settings.svg";
import { ReactComponent as IcLogout } from "../../assets/icons/logout.svg";
import { ReactComponent as IcJourney } from "../../assets/icons/journey.svg";
import { ReactComponent as IcVehicles } from "../../assets/icons/vehicles.svg";
import { ReactComponent as IcDrivingBehavior } from "../../assets/icons/sensors.svg";
import { ReactComponent as IcSensor } from "../../assets/icons/ic-sensor.svg";
import { ReactComponent as IcFences } from "../../assets/icons/fences.svg";
import { ReactComponent as IcTraking } from "../../assets/icons/traking.svg";
import { ReactComponent as IcAlert } from "../../assets/icons/ic_alert.svg";

import { Container } from "./styles";
import { NavLink } from "react-router-dom";
import LanguageDropDown from "../LanguageDropDown";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { getUser, logout, updateUser } from "../../core/redux/reducer/auth";
import { type StylesConfig } from "react-select";
import { Company } from "../../core/interfaces/Company";
import { UserService } from "../../core/services/UserService";
import { isErrorResult } from "../../core/utils/api";
import { limitText } from "../../utils/helper";
import { getProfiles } from "../../core/redux/reducer/user";
import { ProtectedComponent } from "../ProtectedComponent";
import { module_names } from "../../constants/module_names";

interface IMenu {
  name: string;
  route: string;
  icon: React.ReactNode;
}
const SideBar: React.FC = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { control, getValues, setValue } = useForm();
  const user = useAppSelector(getUser);

  const [isExpand, setIsExpand] = useState(false);
  const [show, setShow] = useState(false);
  const [companyList, setCompanyList] = useState<Company[]>([]);

  const menus = [
    // { name: "Dashboard", route: "/dashboard", icon: <IcDashboard /> },
    {
      name: t("aside.tracking"),
      route: "/tracking",
      icon: <IcTraking />,
      profile_name: module_names.TRACKING_ROUTES,
    },
    {
      name: t("aside.routing"),
      route: "/routing",
      icon: <IcRouting />,
      profile_name: module_names.ROUTING,
    },
    {
      name: t("aside.vehicles"),
      route: "/vehicles",
      icon: <IcVehicles />,
      profile_name: module_names.VEHICLES,
    },
    {
      name: t("aside.drivers"),
      route: "/drivers",
      icon: <IcDrivers />,
      profile_name: module_names.DRIVERS,
    },
    {
      name: t("aside.journey"),
      route: "/journey",
      icon: <IcJourney />,
      profile_name: module_names.HOS,
    },
    // {
    //   name: "DrivingBehavior",
    //   route: "/driving-behavior",
    //   icon: <IcDrivingBehavior />,
    // },
    {
      name: t("aside.trips"),
      route: "/trips",
      icon: <IcTrips />,
      profile_name: module_names.TRIP_PLAYBACK,
    },
    {
      name: t("aside.sensors"),
      route: "/sensors",
      icon: <IcSensor />,
      profile_name: module_names.SENSORS,
    },
    {
      name: t("aside.fences"),
      route: "/fences",
      icon: <IcFences />,
      profile_name: module_names.GEOFENCES,
    },
    {
      name: t("aside.alerts"),
      route: "/alerts",
      icon: <IcAlert />,
      profile_name: module_names.ALERTS,
    },
  ];

  function handleLogout() {
    dispatch(logout(null));
  }

  async function getCompaniesList() {
    try {
      const getCompanyService = await UserService.listCompany({});
      console.log(user);
      if (!isErrorResult(getCompanyService)) {
        setCompanyList(getCompanyService.data);

        if (user?.company_id) {
          const companyToSelect = getCompanyService.data.find(
            (company) => company.id === user.company_id
          );
          const convertedCompanyToOption = {
            value: companyToSelect?.id,
            label: companyToSelect?.company_name,
          };
          setValue("selected_company", convertedCompanyToOption);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function updateCompany(company_id: number) {
    if (!company_id) return;

    try {
      const company_response = await UserService.updateCompany(company_id);

      if (!isErrorResult(company_response)) {
        //update the localStorage with the new company
        dispatch(
          updateUser({
            ...user,
            company_id: getValues("selected_company").value,
          })
        );

        setShow(true);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // const handlePathnameOfSubroutes: () => string = () => {
  //   if (pathname.split("/").length <= 2) return pathname;

  //   return pathname
  //     .split("/", 2) // Transforma a string em um array, usando delimitador "/".
  //     .join("/"); // Transforma o array em string, adicionando o delimitador "/".
  // };

  const handleExpand = (): void => {
    if (isExpand) {
      setShow(false);
    }
    setIsExpand(!isExpand);
  };

  const colourStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#202B30",
      border: "#212529",
    }),
    menu: (styles) => ({ ...styles, backgroundColor: "#202B30" }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? "#202B30"
        : isSelected
        ? "#212529"
        : isFocused
        ? "#212529"
        : "#202B30",
    }),
    singleValue: (styles) => ({ ...styles, color: "#ffffff" }),
    input: (styles) => ({ ...styles, color: "#ffffff" }),
  };

  useEffect(() => {
    getCompaniesList();
  }, []);

  useEffect(() => {
    localStorage.setItem("@FLEET_DESK_LANGUAGE", i18n.language);
  }, [i18n.language]);

  return (
    <Container expanded={isExpand}>
      <Stack className="menu" direction="vertical">
        {/* <div className="logo">
          <IcLogo />
        </div> */}

        <div className="expander-outer">
          <div className="autocomplete-input-outer">
            <label htmlFor="alterar">{t("sidebar.change_company")}</label>
            <Controller
              control={control}
              name="selected_company"
              render={({ field: { value, onChange, onBlur } }) => (
                <AsyncPaginate
                  id="alterar"
                  className="company"
                  classNamePrefix="select-company"
                  styles={colourStyles}
                  isClearable
                  placeholder={t("sidebar.search")}
                  noOptionsMessage={(value) => t("not_found")}
                  loadOptions={(inputValue: string): Promise<any> => {
                    const translatedOptions = companyList.map(
                      (company, index) => ({
                        value: company.id,
                        label: company.company_name,
                      })
                    );

                    const filteredOptions = translatedOptions.filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    );

                    const limitedOptionsText = filteredOptions.map((option) => {
                      return {
                        value: option.value,
                        label: limitText(option.label, 27),
                      };
                    });

                    return Promise.resolve({
                      options: limitedOptionsText,
                      hasMore: false,
                    });
                  }}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    if (e) {
                      updateCompany(e.value);
                    }
                  }}
                  onBlur={onBlur}
                />
              )}
            />
          </div>
          <div className="expander" onClick={() => handleExpand()}>
            <IcPajamas />
          </div>
        </div>

        <div className="px-3">
          {show && (
            <Alert
              className="company_alert"
              style={{ background: "rgb(33, 37, 41)" }}
              variant="info"
              onClose={() => setShow(false)}
              dismissible
            >
              <Alert.Heading>{t("company.success")}</Alert.Heading>
            </Alert>
          )}
        </div>

        <nav className="menu-items">
          {menus.map((item, index) => (
            <ProtectedComponent
              key={`${index}`}
              module_name={item.profile_name}
              property="view"
            >
              <NavLink
                to={item.route}
                className={({ isActive }) =>
                  isActive ? "menu-item active" : "menu-item"
                }
              >
                {item.icon} <span className="menu-item__text">{item.name}</span>
              </NavLink>
            </ProtectedComponent>
          ))}
        </nav>

        <div className="actions">
          <NavLink
            to={"/configs/company"}
            className={({ isActive }) =>
              isActive ? "menu-item active" : "menu-item"
            }
          >
            <IcSettings />
          </NavLink>

          <div className={`menu-item`} onClick={handleLogout}>
            <IcLogout />
          </div>
          <div className="action-item">
            <LanguageDropDown />
          </div>
        </div>
      </Stack>
    </Container>
  );
};

export default SideBar;
